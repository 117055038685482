import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/core"

import SEO from "../components/seo"
import { ContentLinks, ContentLinksContainer } from "../components/contentLinks"

export const Title = styled.h1`
  margin-bottom: 0;
`

export const Subtitle = styled.div`
  font-size: 0.95rem;
  font-weight: normal;
  padding: 0.2rem 0 0.3rem;
`

export const Date = styled.div`
  font-size: 0.9rem;
  margin-bottom: 1.58rem;
`

export const capitalize = css`
  text-transform: capitalize;
`

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <Global
        // Remove the underline that is added to header anchor links.
        styles={css`
          .headerLinkIcon {
            background-image: none;
          }
        `}
      />
      <div>
        <Title>{post.frontmatter.title}</Title>
        <Subtitle>{post.frontmatter.description}</Subtitle>
        <Date>{post.frontmatter.date}</Date>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
      <ContentLinksContainer>
        <ContentLinks pageType={post.frontmatter.category} />
      </ContentLinksContainer>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        category
      }
    }
  }
`
