/**
 * Displays links to the latest content of a particular category.
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"

export const Container = styled.div`
  width: 305px;
  float: left;
  padding-right: 15px;
`

export const Heading = styled.div`
  font-size: 0.95rem;
  color: #555;
  font-weight: bold;
  margin-bottom: 0.2rem;
`

export const Page = styled.div`
  padding: 0;
  margin-bottom: 0.1rem;
`

export const Title = styled.div`
  font-size: 0.85rem;
  font-weight: 100;
  color: #1976d2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ContentLinksContainer = styled.div`
  display: inline-block;
`

export const ContentLinks = ({ pageType, title }) => {
  const data = useStaticQuery(graphql`
    fragment PageDetails on MarkdownRemark {
      frontmatter {
        date
        description
        title
        category
      }
      fields {
        slug
      }
    }
    query {
      info: allMarkdownRemark(
        limit: 4
        filter: { frontmatter: { category: { eq: "info" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            ...PageDetails
          }
        }
      }
      tutorial: allMarkdownRemark(
        limit: 4
        filter: { frontmatter: { category: { eq: "tutorial" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            ...PageDetails
          }
        }
      }
      review: allMarkdownRemark(
        limit: 4
        filter: { frontmatter: { category: { eq: "review" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            ...PageDetails
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Heading>{title}</Heading>
      {data[pageType].edges.map(({ node }) => (
        <Page>
          <Link to={node.fields.slug}>
            <Title title={node.frontmatter.title}>
              {node.frontmatter.title}
            </Title>
          </Link>
        </Page>
      ))}
    </Container>
  )
}

ContentLinks.defaultProps = {
  pageType: `info`,
  title: "Something else to read...",
}

ContentLinks.propTypes = {
  pageType: PropTypes.string,
  title: PropTypes.string,
}
